import { PepTypography } from "@components/pep-typography";
import PlatformErrorImage from "@components/platform-error/platform-error-image";
import type { ErrorComponentProps } from "@components/platform-error/platform-error-types";
import { getErrorMessageTSX } from "@components/platform-error/platform-error-utils";
import { Stack, Alert, Divider } from "@infinitaslearning/pixel-design-system";

const NOTIFICATION_SX = {
  maxWidth: "75vw",
  maxHeigth: "25vh",
  overflow: "auto",
  margin: 1,
};

export const NotificationError: React.FC<ErrorComponentProps> = ({
  errorType,
  t,
  errorCode,
  customMessage,
  message,
  title,
  children,
  CTA,
}) => {
  return (
    <Stack sx={NOTIFICATION_SX} data-testid="notification-error">
      <Alert
        severity="error"
        icon={<PlatformErrorImage errorType={errorType} displayType={"notification"} />}
      >
        <PepTypography variant="body1Bold">{title}</PepTypography>
        <Stack m={1} p={1} mr={5}>
          {getErrorMessageTSX(t, errorCode, customMessage, message)}
          <Stack>{children}</Stack>
          {CTA !== null && (
            <Divider orientation="horizontal" sx={{ my: 1, mt: 2 }} flexItem>
              <Stack>{CTA}</Stack>
            </Divider>
          )}
        </Stack>
      </Alert>
    </Stack>
  );
};
