import { useWS } from "@components/web-socket-context/web-socket-client-provider";
import { getAnalyticsTracker } from "@lib/analytics-tracker";
import { getLocalSession } from "@lib/session-store";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  type QueryFunctionContext,
} from "@tanstack/react-query";
import { getEnvironment } from "@utils/common";
import type { GraphQLError } from "graphql";
import type { GetServerSidePropsContext } from "next";
import fetch from "node-fetch";
import type { ParsedUrlQuery } from "node:querystring";
import { useEffect } from "react";
import { getApmWeb } from "@lib/observability";
import { Cookies } from "react-cookie";
import {
  type PublicationEnv,
  USER_ENV_COOKIE_NAME,
} from "@utils/persisted-values/use-publication-env";

type INextContext = GetServerSidePropsContext<ParsedUrlQuery>;

const ERROR_LOCATION = "react-query";

const UNAUTHENTICATED_ERROR_STRING = "Unauthenticated";
export const FETCH_ERROR_STRING = "Failed to fetch";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    onError: (err: any, query) => {
      if (err.message === UNAUTHENTICATED_ERROR_STRING) {
        return;
      }

      getApmWeb().captureError(
        new Error(
          `${(err as Error).message} - with variables ${JSON.stringify(query.queryKey[1] || {})}`,
        ),
        ERROR_LOCATION,
      );
    },
  }),
  mutationCache: new MutationCache({
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
    onError: (err: any, variables, _context, mutation) => {
      if (err.message === UNAUTHENTICATED_ERROR_STRING) {
        return;
      }

      getApmWeb().captureError(
        new Error(
          `${(err as Error).message} - with variables ${JSON.stringify(
            variables || {},
          )} in mutation ${mutation}`,
        ),
        ERROR_LOCATION,
      );
    },
  }),
});

export const REACT_QUERY_OPTS_15MINSTALE = {
  staleTime: 1000 * 60 * 15,
  cacheTime: 1000 * 60 * 15,
};

const getEndpoint = (): string => {
  if (getEnvironment() === "staging") {
    return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_STAGING as string;
  }

  if (!process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT) {
    if (process.env.NODE_ENV !== "test") {
      getApmWeb().captureError(new Error("production graphql endpoint not set"), ERROR_LOCATION);
    } else {
      return "https://test.com";
    }
  }

  return process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT as string;
};

// Changing the name of this function requires to update `codegen.yml` configuration
export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit["headers"],
): ((
  queryContext?: QueryFunctionContext,
  nextContext?: INextContext | undefined,
) => Promise<TData>) => {
  const tracker = getAnalyticsTracker();
  const productAnalyticsSessionId = tracker?.getSessionId();

  return async (_queryContext, nextContext) => {
    const { session, status } = getLocalSession();
    const token = session ? `Bearer ${session?.idToken}` : undefined;
    const cookies = new Cookies(nextContext?.req.headers.cookie);
    const pubEnv: PublicationEnv = cookies.get(USER_ENV_COOKIE_NAME);
    if (status !== "authenticated") {
      getApmWeb().captureError(
        new Error(
          `The session has an unexpected status.\nStatus: ${status}\nHas Token: ${!!token}\nOperation: ${query}\nVariables: ${variables}`,
        ),
        ERROR_LOCATION,
      );
    }

    if (!token) {
      getApmWeb().captureError(
        new Error(`Unable to get a valid session.\nOperation: ${query}\nVariables: ${variables}`),
        ERROR_LOCATION,
      );
      throw new Error(UNAUTHENTICATED_ERROR_STRING);
    }

    const res = await fetch(getEndpoint(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "analytics-session-id": productAnalyticsSessionId || "",
        authorization: token || "",
        ...(options || []),
        ...(pubEnv ? { "pub-env": pubEnv } : {}),
        // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
      } as any, // TODO: Remove any, but getting a strange type error otherwise
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      const isUnauthenticated = json.errors?.[0].extensions?.response?.statusCode === 401;
      if (isUnauthenticated) {
        throw new Error(UNAUTHENTICATED_ERROR_STRING);
      }

      const { message } = json.errors[0] || "Error..";
      throw new Error(message);
    }

    return json.data;
  };
};

export const useSubscription = <T>(
  subscription: string,
  memoizedVariables: Record<string, unknown> | undefined,
  disabled: boolean,
  memoizedOnNewData: (data: T) => void,
) => {
  const wsClient = useWS();
  useEffect(() => {
    if (disabled) return;
    const payload: { query: string; variables?: Record<string, unknown> } = { query: subscription };
    if (memoizedVariables) {
      payload.variables = memoizedVariables;
    }

    const unsubscribe = wsClient?.subscribe<T>(payload, {
      next: (data) => {
        memoizedOnNewData(data as T);
      },
      error: (error) => {
        if (error instanceof CloseEvent) {
          getApmWeb().captureError(
            new Error(
              `Subscription close error. Code: ${error.code}. Reason: ${error.reason || ""}`,
            ),
            ERROR_LOCATION,
          );
        } else if (error instanceof Error) {
          getApmWeb().captureError(new Error("Subscription error"), ERROR_LOCATION);
        } else {
          const graphQlError = error as GraphQLError[];
          getApmWeb().captureError(
            new Error(
              `Subscription GraphQL error.\n${graphQlError.map((err) => err.message).join(", ")}`,
            ),
            ERROR_LOCATION,
          );
        }
      },
      complete: () => {},
    });

    return () => unsubscribe?.();
  }, [disabled, subscription, wsClient, memoizedVariables, memoizedOnNewData]);
};
